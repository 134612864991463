* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #111;
  color: #ffffff;
  margin: 0;
  padding: 0;
  min-width: 1000px;
  width: auto !important;
}

.rankings {
  background-color: #111;
  color: #fff;
}

.select-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 5px;
}

.select-container select {
  text-align: center;
  margin-right: 10px;
  padding: 2px;
  width: 120px;
  max-width: 120px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  background-color: #333;
  color: #ffffff;
  border: none;
  outline: none;
}

.select-container select:hover,
.select-container select:focus {
  background-color: #333;
}

.search-container {
  text-align: center;
  background-color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #ffffff;
  padding: 2px;
  width: 120px;
  max-width: 120px;
  outline: none;
  border: none;
}

.search-container input {
  text-align: center;
  font-size: 16px;
  border: none;
  outline: none;
}

.hide-first-column {
  display: none;
}

.rankings-page table {
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.rankings-page td {
  padding: 0;
  text-align: center;
}

.rankings-page th {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #222;
  color: #ffffff;
}

.rankings-page thead th {
  border: none;
}

.rankings-page tbody {
  background: #333;
}

.rankings-page tbody tr:hover {
  background-color: #444;
}

.rankings-page tbody tr {
  height: 50px;
  max-height: 50px;
}

.rankings-page td:nth-child(3) {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
}

.rankings-page th:nth-child(3) {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.rankings-page td:nth-child(4) {
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
  max-height: 30px;
  min-height: 30px;
  display: flex;
  margin: auto;
}

.rankings-page td:nth-child(4) img {
  display: block;
  margin-top: 10px;
  max-width: 100%;
  max-height: 100%;
}

.rankings-page th:nth-child(4) {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.rankings-page td:nth-child(5),
.rankings-page th:nth-child(5) {
  width: 300px;
  max-width: 300px;
}

.rankings-page td:nth-child(1),
.rankings-page td:nth-child(6),
.rankings-page td:nth-child(10),
.rankings-page th:nth-child(1),
.rankings-page th:nth-child(6),
.rankings-page th:nth-child(10) {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.rankings-page td:nth-child(2),
.rankings-page td:nth-child(7),
.rankings-page td:nth-child(8),
.rankings-page td:nth-child(9),
.rankings-page th:nth-child(2),
.rankings-page th:nth-child(7),
.rankings-page th:nth-child(8),
.rankings-page th:nth-child(9) {
  width: 75px;
  max-width: 75px;
  min-width: 75px;
}

.rankings-page td img {
  height: 100%;
  width: 100%;
  display: block;
}

.charts {
  background-color: #111;
  color: #fff;
}

.select-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.select-container1 select {
  text-align: center;
  margin-right: 10px;
  padding: 2px;
  width: 140px;
  max-width: 140px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  background-color: #333;
  color: #ffffff;
  border: none;
  outline: none;
}

.select-container1 select:hover,
.select-container1 select:focus {
  background-color: #333;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.checkbox-container input {
  margin-right: 5px;
}

.checkbox-container input:checked {
  accent-color: #333;
}

.chart-container {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
}

.custom-tooltip {
  background-color: #333;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltip-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.tooltip-count {
  color: #888;
}

.players-page table {
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.players-page td {
  padding: 0;
  text-align: center;
}

.players-page th {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #222;
  color: #ffffff;
}

.players-page thead th {
  border: none;
}

.players-page tbody {
  background: #333;
}

.players-page tbody tr:hover {
  background-color: #444;
}

.players-page tbody tr {
  height: 50px;
  max-height: 50px;
}

.players-page td:nth-child(2) {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
}

.players-page th:nth-child(2) {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.players-page td:nth-child(3) {
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
  max-height: 30px;
  min-height: 30px;
  display: flex;
  margin: auto;
}

.players-page td:nth-child(3) img {
  display: block;
  margin-top: 10px;
  max-width: 100%;
  max-height: 100%;
}

.players-page th:nth-child(3) {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.players-page td:nth-child(4),
.players-page th:nth-child(4) {
  width: 300px;
  max-width: 300px;
}

.players-page td:nth-child(5),
.players-page th:nth-child(5),
.players-page td:nth-child(6),
.players-page th:nth-child(6),
.players-page td:nth-child(7),
.players-page th:nth-child(7) {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}

.players-page td:nth-child(1),
.players-page th:nth-child(1) {
  width: 75px;
  max-width: 75px;
  min-width: 75px;
}

.players-page td img {
  height: 100%;
  width: 100%;
  display: block;
}

.page-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  z-index: 1000;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #333;
}

.button.active {
  background-color: #333;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
